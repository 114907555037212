<template>
    <div>
        <div class="card">
            <div class="bg-blue-light px-1 rounded pb-2">
                <TitleButton
                        class="mt-1"
                        btnTitle="Go Back"
                        title="Edit Payment"
                        @onClickCloseButton="goToList"
                />

                <div class="row mt-1">
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Date</label>
                        <input
                                class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                                tabindex="1"
                                type="date"
                                placeholder=""
                                v-model="payment.date"
                        >
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label text-end">Voucher</label>
                        <input type="hidden" class="form-control invoice-edit-input date-picker flatpickr-input"
                               readonly="readonly">
                        <div
                                class="input-group input-group-merge invoice-edit-input-group has-validation"
                                :class="isEmptyVoucher ? 'is-invalid' : ''"
                        >
                            <div class="input-group-text">
                                <span :class="isEmptyVoucher ? 'text-danger' : ''">{{ payment.voucher_prefix }}-</span>
                            </div>
                            <input
                                    type="text"
                                    class="form-control invoice-edit-input"
                                    aria-describedby="validationVoucherNameFeedback"
                                    placeholder=""
                                    v-model="payment.voucher_serial"
                                    :class="isEmptyVoucher ? 'is-invalid' : ''"
                            >
                            <div id="validationVoucherNameFeedback" class="invalid-feedback">
                                Voucher number is required
                            </div>
                        </div>
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Paid from: </label>
                        <v-select
                                placeholder="Select Cash & Bank Account"
                                class="w-100"
                                v-model="credit.account_head_id"
                                :options="accountHeads"
                                label="name"
                                :reduce="name => name.id"
                        />
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Party: </label>
                        <v-select
                                placeholder="Select Party"
                                class="w-100"
                                :options="contactProfiles"
                                label="name"
                                :reduce="name => name.id"
                                v-model="payment.contact_profile_id"
                        />
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label text-end">Business</label>
                        <v-select
                                placeholder="Select Business"
                                class="w-100"
                                :options="businesses"
                                label="name"
                                :reduce="name => name.id"
                                v-model="payment.business_id"
                        />
                    </div>
                </div>
            </div>
            <div class="mt-2 px-2 pb-2">
                <template v-for="(item, index) in payment.ledgers" :key="index">
                    <AddFormElement
                            class="mb-2"
                            :accountHeads="accountHeads"
                            v-if="item.credit == null"
                            :index="index"
                            :item="item"
                            @onClose="onClose"
                    />
                </template>

                <div class="row me-1 mt-3">
                    <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
                        <AddButton title="Add line" @onClickAdd="onClickAdd"/>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-4"><input readonly type="number" class="form-control text-end"
                                                                :value="totalDebit"></div>
                    <!-- <div class="col-6 col-sm-4 col-lg-2"><input readonly type="number" class="form-control" :value="totalCredit"></div> -->
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="note" class="form-label fw-bold">Memo</label>
                            <textarea v-model="payment.note" placeholder="Memo" class="form-control" rows="2"
                                      id="note"></textarea>
                        </div>
                    </div>
                </div>

                <div class="my-2 text-center">
                    <hr class="mb-1">
                    <button
                            :disabled="paymentLoading || isEmptyVoucher"
                            @click="updatePayment()"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="paymentLoading" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Update
                    </button>
                    <button
                            @click="goToList"
                            class="btn btn-outline-secondary cancel-btn"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>

        <VoucherValidationModal
                ref="voucherValidationModal"
                :voucherQuery="voucherQuery"
                @on-press-ok="setVoucherNumberAndDate(true)"
        />
    </div>
</template>

<script>
import handlePayment from '@/services/modules/payment'
import { inject } from 'vue'

import handleContact from '@/services/modules/contact'
import handleCBusinesses from '@/services/modules/businesses'
// import { useStore } from 'vuex'
// import { computed } from 'vue'
// import {mapMutations} from 'vuex'

import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/company/payment/AddFormElement'
import AddButton from '@/components/atom/AddButton'
import {editVoucherNumber, generateQuery, generateVoucherNumber} from "@/services/utils/voucherNumberGenerator";
import VoucherValidationModal from "@/components/molecule/company/voucher/VoucherValidationModal.vue";
import figureFormatter from "@/services/utils/figureFormatter";

export default {
    name: 'PaymentEdit',
    components: {
        VoucherValidationModal,
        TitleButton,
        AddFormElement,
        AddButton
    },
    data: () => ({
        accountHeads: [],
        contactProfiles : [],
        businesses : [],
        saveNewLoader: false,
        isEmptyVoucher: false,
        credit: {
            account_head_id: null
        },
        payment: {
            voucher_prefix : '',
            voucher_serial : '',
            voucher_no : '',
            contact_profile_id: null,
            business_id: null,
            voucher_type: 'payment_voucher',
            ledgers: []
        }
    }),
    computed: {
        isLoading () {
            return this.paymentLoading || this.saveNewLoader
        },
        start () {
            return this.$route.query.start
        },
        end () {
            return this.$route.query.end
        },
        general () {
            return this.payment.ledgers
        },
        totalDebit () {
            let total = 0;
            this.general.map(i => {
                if(i.debit) total += i.debit
            })
            return total
        },
        voucherQuery () {
            return generateQuery(this.$route.params.companyId, 'payment_voucher', 'general_payment');
        }
    },

    watch: {
        'payment.voucher_prefix':function () {
            this.concatVoucher()
        },

        'payment.voucher_serial':function () {
            this.concatVoucher()
        }
    },

    methods: {
        concatVoucher(){
            this.isEmptyVoucher = false;

            if(! this.payment.voucher_serial) {
                this.isEmptyVoucher = true;
                return ;
            }

            let voucherSerial = this.decimalFormat(this.payment.voucher_serial.toString().replace(/[^0-9\.]+/g, ''))
            if(voucherSerial === '') {
                this.isEmptyVoucher = true;
            }
            this.payment.voucher_serial = voucherSerial;
            this.payment.voucher_no = `${this.payment.voucher_prefix}-${voucherSerial}`;
        },
        
        async getPayment() {
           // return;
            try {
                this.paymentLoading = true
                let res = await this.fetchSinglePayment(this.$route.params.paymentId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.payment = res.data
                    this.setData()
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.paymentLoading = false
            }
        },
        async getAccountHeads() {
           // return;
            try {
                this.paymentLoading = true
                let res = await this.fetchAccountHeads(this.$route.params.companyId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.accountHeads = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.paymentLoading = false
            }
        },
        async getContactProfiles() {
            try {
                let companyId = this.$route.params.companyId
                let contactId = '';
                let q = '';
                let query = '?company_id=' + companyId + '&q=' + q + '&contact_id' + contactId;
                let res = await this.fetchContactProfiles(query)

                if (res.status) {
                    this.contactProfiles = res.data;
                }

            } catch (err) {
                if (!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },
        async getBusinesses() {
            try {
                let companyId = this.$route.params.companyId
                let res = await this.fetchBusinessList('?company_id=' + companyId)

                if(res.status) {
                    this.businesses = res.data;
                }

            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if (err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }

        },

        setVoucherNumberAndDate(withoutDate = false) {
            new Promise(async (resolve, reject) => {
                try {
                    if(! withoutDate) {
                        this.payment.date = new Date().toISOString().split('T')[0]
                    }
                    let query = this.voucherQuery;
                    let voucher = await generateVoucherNumber(query);
                    this.payment.voucher_prefix = voucher.prefix;
                    this.payment.voucher_serial = voucher.serial;
                    resolve();
                } catch (err) {
                    reject(err);
                }
            })

        },

        goToList() {
            this.$router.push({name: 'payment-voucher-list', 
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }
            
            })
        },
        onClose(index) {
            this.payment.ledgers.splice(index, 1)
        },
        onClickAdd() {
            this.payment.ledgers.push({
                account_head_id: null,
                debit: null,
                credit: null,
                note: '',
                tax_rate: null,
                taxable_amount: null
            })
        },
        setData() {
            let ledger = this.general.find(i => i.debit === null)
            if(ledger) this.credit.account_head_id = ledger.account_head_id
        },
        getFormData () {
            let formData = new FormData();
            this.payment._method = 'PATCH'
            Object.keys(this.payment).forEach(i => {
                if(i !== 'ledgers' && i !== 'business_id' && i !== 'contact_profile_id') {
                    formData.append(i, this.payment[i])
                }
            })
            if(this.payment.business_id) formData.append('business_id', this.payment.business_id)
            if(this.payment.contact_profile_id) formData.append('contact_profile_id', this.payment.contact_profile_id)
            let generalLedgerData =  this.general.map((ledger) => {
              ledger.contact_profile_id = this.payment.contact_profile_id;
              ledger.business_id = this.payment.business_id;
              return ledger
            })
            let debitObj = generalLedgerData.find(i => i.debit === null)
                debitObj.account_head_id = this.credit.account_head_id
                debitObj.credit = this.totalDebit
            formData.append('general_ledgers', JSON.stringify(generalLedgerData))
            return formData;
        },
        async updatePayment () {
            this.concatVoucher();
            this.payment.company_id = this.$route.params.companyId;
            let data = this.getFormData();
            this.paymentLoading = true
            try {
                let res = await this.editPayment(this.payment.id, data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    setTimeout(() => {this.goToList()},1500)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                    return ;
                }

                if(err.response && err.response.status !== 406) {
                    this.showError(err.response?.data.message)
                }
                if(err.response.data.data && err.response.data.data.show_modal) {
                    this.$refs.voucherValidationModal.openModal(err.response?.data.message)
                }
            } finally {
                this.paymentLoading = false
            }
        },
        onChangeDate (e) {
            this.payment.date = e.target.value
        }
    },

    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        const {decimalFormat} = figureFormatter();

        const {
            fetchAccountHeads,
            editPayment,
            fetchSinglePayment,
            paymentLoading
        } = handlePayment()

        const {
          fetchContactProfiles,
        } = handleContact()

        const {
          fetchBusinessList,
        } = handleCBusinesses()

        return {
            fetchAccountHeads,
            fetchContactProfiles,
            fetchBusinessList,
            editPayment,
            fetchSinglePayment,
            decimalFormat,
            paymentLoading,
            showError,
            showSuccess
        }
    },

    async mounted() {
        await this.getPayment()
        await this.getAccountHeads()
        await this.getContactProfiles()
        await this.getBusinesses()
    
        let voucher = editVoucherNumber(this.payment.voucher_no);
        this.payment.voucher_prefix = voucher.prefix;
        this.payment.voucher_serial = voucher.serial;
    }
}
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
</style>
